import { Injectable, OnDestroy } from '@angular/core';
import { StbState } from '../../state/stb.reducer';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as StbSelectors from '../../state/stb.selectors';
import { ExternalInput, ExternalInputResponse } from '../types/types';

declare var idcap: any;

@Injectable({
  providedIn: 'root',
})
export class IdcapService implements OnDestroy {
  stbState: StbState;
  stbStateSubscription: Subscription;
  constructor(private store$: Store) {
    this.stbStateSubscription = this.store$
      .select(StbSelectors.selectStbState)
      .subscribe((state) => {
        this.stbState = state;
      });
  }

  ngOnDestroy(): void {
    this.stbStateSubscription.unsubscribe();
  }

  isAvailable(): boolean {
    return navigator.userAgent.includes('Web0S');
  }

  getInstantPower(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'instant_power',
        },
        onSuccess: (r) => {
          console.info(`onSuccess : getInstantPower : ${JSON.stringify(r)}`);
          resolve(r);
        },
        onFailure: (e) => {
          const errorMessage = `onFailure : getInstantPower : ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setInstantPowerOn(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/set', {
        parameters: {
          key: 'instant_power',
          value: '2',
        },
        onSuccess: (r) => {
          console.info(`onSuccess : setInstantPowerOn : ${JSON.stringify(r)}`);
          resolve(r);
        },
        onFailure: (e) => {
          const errorMessage = `onFailure : setInstantPowerOn : ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setInstantPowerOff(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/set', {
        parameters: {
          key: 'instant_power',
          value: '0',
        },
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: setInstantPowerOff : ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getProcentricServer(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://tv/procentricserver/get', {
        parameters: {},
        onSuccess: (p) => {
          console.log(
            'onSuccess : Pro:Centric server mode = ' +
              p.mode +
              ', media = ' +
              p.media
          );
          if (p.media === 'rf') {
            console.log(
              'RF server type = ' +
                p.rfServer.type +
                ', data channel number = ' +
                p.rfServer.dataChannelNumber
            ); // US
            console.log(
              'RF server type = ' +
                p.rfServer.type +
                ', frequency = ' +
                p.rfServer.freqeuncy
            ); // EU
          } else if (p.media === 'ip') {
            if (p.ipServer.type === 'ip') {
              console.log(
                'IP server : type = ip, ip address = ' +
                  p.ipServer.ip +
                  ', port = ' +
                  p.ipServer.port
              );
            } else if (p.ipServer.type === 'domain_name') {
              console.log(
                'IP server : type = domain_name, domain name = ' +
                  p.ipServer.domainName +
                  ', port = ' +
                  p.ipServer.port
              );
            }
          }
          resolve(p);
        },
        onFailure: (err) => {
          console.log('onFailure : errorMessage = ' + err.errorMessage);
          reject(err.errorMessage);
        },
      });
    });
  }

  getIdcapMode(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/idcapmode/get', {
        parameters: {},
        onSuccess: (s) => {
          console.log(`onSuccess : idcap mode =  ${s.mode}`);
          resolve(s.mode);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : getIdcapMode = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setIdcapMode0(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/idcapmode/set', {
        parameters: {
          mode: 0,
        },
        onSuccess: (result) => {
          console.log(`onSuccess : setIdcapMode0`);
          resolve(result);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setIdcapMode0 = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setIdcapMode1(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/idcapmode/set', {
        parameters: {
          mode: 1,
        },
        onSuccess: (result) => {
          console.log(`onSuccess : setIdcapMode1`);
          resolve(result);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setIdcapMode1 = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setIdcapMode2(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/idcapmode/set', {
        parameters: {
          mode: 2,
        },
        onSuccess: (result) => {
          console.log(`onSuccess`);
          resolve(result);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setIdcapMode2 = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setIdcapMode3(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/idcapmode/set', {
        parameters: {
          mode: 3,
        },
        onSuccess: (result) => {
          console.log(`onSuccess`);
          resolve(result);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setIdcapMode3 = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setIdcapMode4(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/idcapmode/set', {
        parameters: {
          mode: 4,
        },
        onSuccess: (result) => {
          console.log(`onSuccess`);
          resolve(result);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setIdcapMode4 = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getMacAddress(): Promise<any> {
    /*
    Try's hardware first, then local storage,
    then returns hard coded default if not available
    */
    const DEFAULT_MAC = 'A0:B1:C2:D3:E4:F5';
    let mac;

    if (!this.isAvailable()) {
      return new Promise((resolve, reject) => {
        mac = localStorage.getItem('macAddress');
        if (mac) {
          console.info(
            'configuration : got MAC address from localStorage : ' +
              mac.toUpperCase()
          );
          resolve(mac.toUpperCase());
        } else {
          mac = DEFAULT_MAC;
          console.info(
            'configuration : MAC address not found in localStorage, using default : ' +
              mac.toUpperCase()
          );
          resolve(mac.toUpperCase());
        }
      });
    }

    return this.getNetworkInformation()
      .then((r: any) => {
        return r.wired.mac.toUpperCase();
      })
      .catch(() => {
        mac = localStorage.getItem('macAddress');
        if (mac) {
          console.info(
            'configuration : got MAC address from localStorage : ' +
              mac.toUpperCase()
          );
        } else {
          mac = DEFAULT_MAC;
          console.info(
            'configuration : MAC address not found in localStorage, using default : ' +
              mac.toUpperCase()
          );
        }
        return mac.toUpperCase();
      });
  }

  getNetworkInformation(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://network/configuration/get', {
        parameters: {},
        onSuccess: (result) => {
          console.log(
            `onSuccess : getNetworkInformation : ${JSON.stringify(result)}`
          );
          resolve(result);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : getNetworkInformation = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setBrowserDebugModeOn(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/set', {
        parameters: {
          key: 'browser_debug_mode',
          value: '1',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : setBrowserDebugModeOn = ' +
              err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setBrowserDebugModeOff(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/set', {
        parameters: {
          key: 'browser_debug_mode',
          value: 0,
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : setBrowserDebugModeOff = ' +
              err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getModelName(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'model_name',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : getModelName = ' + err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getHardwareVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'hardware_version',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : getHardwareVersion = ' +
              err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getSerialNumber(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'serial_number',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : getSerialNumber = ' + err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getFirmwareVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'firmware_version',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : getFirmwareVersion = ' +
              err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getIdpn(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'idpn',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess : getIdpn = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : getIdpn = ' + err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getWebOsVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'webos_version',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : getWebOsVersion = ' + err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getDisplayResolution(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'display_resolution',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : getDisplayResolution = ' +
              err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setDisplayResolution720(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/set', {
        parameters: {
          key: 'display_resolution',
          value: '1280x720',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : setDisplayResolution720 = ' +
              err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setDisplayResolution1080(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/property/set', {
        parameters: {
          key: 'display_resolution',
          value: '1920x1080',
        },
        onSuccess: (cbObject) => {
          console.log('onSuccess cbObject = ' + JSON.stringify(cbObject));
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : errorMessage : setDisplayResolution1080 = ' +
              err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  powerOffTv(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://power/command', {
        parameters: {
          powerCommand: 'powerOff',
        },
        onSuccess: () => {
          console.log('onSuccess');
          resolve(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : powerOffTv =  ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  rebootTv(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://power/command', {
        parameters: {
          powerCommand: 'reboot',
        },
        onSuccess: () => {
          console.log('onSuccess');
          resolve(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : rebootTv =  ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getPowerMode(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://power/powermode/get', {
        parameters: {},
        onSuccess: (s) => {
          console.log(`onSuccess : power mode = ${s.mode}`);
          resolve(s.mode);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : getPowerMode = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setPowerModeWarm(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://power/powermode/set', {
        parameters: {
          mode: 'WARM',
        },
        onSuccess: () => {
          console.log('onSuccess');
          resolve(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setPowerModeWarm = ${JSON.stringify(
              err
            )}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setPowerModeNormal(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://power/powermode/set', {
        parameters: {
          mode: 'NORMAL',
        },
        onSuccess: () => {
          console.log('onSuccess');
          resolve(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setPowerModeNormal = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  powerOffTvObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://power/command', {
        parameters: {
          powerCommand: 'powerOff',
        },
        onSuccess: () => {
          console.log('onSuccess');
          observer.next(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : powerOffTv =  ${err.errorMessage}`
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  rebootTvObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://power/command', {
        parameters: {
          powerCommand: 'reboot',
        },
        onSuccess: () => {
          console.log('onSuccess');
          observer.next(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : rebootTv =  ${err.errorMessage}`
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  getPowerModeObs(): Observable<any> {
    console.log(`getPowerModeObs called`);
    return new Observable((observer) => {
      idcap.request('idcap://power/powermode/get', {
        parameters: {},
        onSuccess: (s) => {
          console.log(`onSuccess: power mode = ${s.mode}`);
          observer.next(s.mode);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : getPowerMode = ${err.errorMessage}`
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  setPowerModeWarmObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://power/powermode/set', {
        parameters: {
          mode: 'WARM',
        },
        onSuccess: () => {
          console.log('onSuccess');
          observer.next(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setPowerModeWarm = ROG -  ${JSON.stringify(
              err
            )}`
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  setPowerModeNormalObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://power/powermode/set', {
        parameters: {
          mode: 'NORMAL',
        },
        onSuccess: () => {
          console.log('onSuccess');
          observer.next(null);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setPowerModeNormal = ${err.errorMessage}`
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  getLocale(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/locale/get', {
        parameters: {},
        onSuccess: (cbObject) => {
          console.log(`onSuccess cbObject =  ${JSON.stringify(cbObject)}`);
          console.log(
            `onSuccess : specifier = ${cbObject.specifier}, language = ${cbObject.language}, country = ${cbObject.country}`
          );
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : getLocale = ` + err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  setLocale(specifier: string): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://configuration/locale/set', {
        parameters: {
          specifier,
        },
        onSuccess: (cbObject) => {
          console.log(`onSuccess cbObject =  ${JSON.stringify(cbObject)}`);
          console.log(
            `onSuccess : specifier = ${cbObject.specifier}, language = ${cbObject.language}, country = ${cbObject.country}`
          );
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setLocale = ` + err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  // getLocaleList(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     hcap.system.getLocaleList({
  //       onSuccess: (s) => {
  //         for (let i = 0; i < s.list.length; i++) {
  //           console.log(
  //             'list[' +
  //               i +
  //               '].specifier = ' +
  //               s.list[i].specifier +
  //               'list[' +
  //               i +
  //               '].language = ' +
  //               s.list[i].language +
  //               'list[' +
  //               i +
  //               '].country = ' +
  //               s.list[i].country
  //           );
  //         }
  //         resolve(s);
  //       },
  //       onFailure: (f) => {
  //         const errorMessage = 'onFailure: getLocaleList : ' + f.errorMessage;
  //         console.error(errorMessage);
  //         reject(errorMessage);
  //       },
  //     });
  //   });
  // }

  async setVideoMute(mute: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://video/mute/set', {
        parameters: {
          videoMute: true,
        },
        onSuccess: () => {
          console.log(`Success`);
          resolve();
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setVideoMute = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  async getForegroundAppInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://application/foregroundapp/get', {
        parameters: {},
        onSuccess: (cbObject) => {
          console.log(`cbObject : ${JSON.stringify(cbObject)}`);
          console.log(`appId = ${cbObject.appId}`);
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : getForegroundAppInfo = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }
  getCpuUsage(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://system/usage/get', {
        parameters: {
          keys: ['cpu', 'memory'],
        },
        onSuccess: (cbObject) => {
          console.log('cbObject : ' + JSON.stringify(cbObject));
          console.log('percentage of used CPU = ' + cbObject.cpuUsage);
          console.log('percentage of used memory = ' + cbObject.memoryUsage);
          console.log('value of total memory = ' + cbObject.totalmemory);
          console.log('value of free memory = ' + cbObject.freememory);
          console.info(
            `onSuccess : getCpuUsage : ${JSON.stringify(cbObject.cpuUsage)}`
          );
          resolve(cbObject.cpuUsage);
        },
        onFailure: (err) => {
          const errorMessage = 'onFailure: getCpuUsage : ' + err.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getMemoryUsage(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://system/usage/get', {
        parameters: {
          keys: ['cpu', 'memory'],
        },
        onSuccess: (cbObject) => {
          console.log('cbObject : ' + JSON.stringify(cbObject));
          console.log('percentage of used CPU = ' + cbObject.cpuUsage);
          console.log('percentage of used memory = ' + cbObject.memoryUsage);
          console.log('value of total memory = ' + cbObject.totalmemory);
          console.log('value of free memory = ' + cbObject.freememory);
          console.info(
            `onSuccess : getMemoryUsage : ${JSON.stringify(
              cbObject.memoryUsage
            )}`
          );
          resolve(cbObject.cpuUsage);
        },
        onFailure: (err) => {
          const errorMessage =
            'onFailure : getMemoryUsage : ' + err.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  // Observables

  getCpuUsageObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://system/usage/get', {
        parameters: {
          keys: ['cpu', 'memory'],
        },
        onSuccess: (cbObject) => {
          console.log('cbObject : ' + JSON.stringify(cbObject));
          console.log('percentage of used CPU = ' + cbObject.cpuUsage);
          console.log('percentage of used memory = ' + cbObject.memoryUsage);
          console.log('value of total memory = ' + cbObject.totalmemory);
          console.log('value of free memory = ' + cbObject.freememory);
          console.info(
            `onSuccess : getCpuUsage : ${JSON.stringify(cbObject.cpuUsage)}`
          );
          observer.next(cbObject);
        },
        onFailure: (err) => {
          const errorMessage = 'onFailure : getCpuUsage : ' + err.errorMessage;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  getMemoryUsageObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://system/usage/get', {
        parameters: {
          keys: ['cpu', 'memory'],
        },
        onSuccess: (cbObject) => {
          console.log('cbObject : ' + JSON.stringify(cbObject));
          console.log('percentage of used CPU = ' + cbObject.cpuUsage);
          console.log('percentage of used memory = ' + cbObject.memoryUsage);
          console.log('value of total memory = ' + cbObject.totalmemory);
          console.log('value of free memory = ' + cbObject.freememory);
          console.info(
            `onSuccess : getMemoryUsage : ${JSON.stringify(
              cbObject.memoryUsage
            )}`
          );
          observer.next(cbObject);
        },
        onFailure: (err) => {
          const errorMessage =
            'onFailure : getMemoryUsage : ' + err.errorMessage;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  getFirmwareVersionObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'firmware_version',
        },
        onSuccess: (cbObject) => {
          console.log(
            'onSuccess : getFirmwareVersionObs : cbObject = ' +
              JSON.stringify(cbObject)
          );
          observer.next(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : getFirmwareVersionObs : errorMessage = ' +
              err.errorMessage
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  getModelNameObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'model_name',
        },
        onSuccess: (cbObject) => {
          console.log(
            'onSuccess : getModelNameObs : cbObject = ' +
              JSON.stringify(cbObject)
          );
          observer.next(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : getModelNameObs : errorMessage = ' + err.errorMessage
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  getSerialNumberObs(): Observable<any> {
    return new Observable((observer) => {
      idcap.request('idcap://configuration/property/get', {
        parameters: {
          key: 'serial_number',
        },
        onSuccess: (cbObject) => {
          console.log(
            'onSuccess : getSerialNumberObs : cbObject = ' +
              JSON.stringify(cbObject)
          );
          observer.next(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : getSerialNumberObs : errorMessage = ' +
              err.errorMessage
          );
          observer.error(err.errorMessage);
        },
      });
    });
  }

  async getCurrentExternalInput(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://externalinput/get', {
        parameters: {},
        onSuccess: (cbObject) => {
          console.log(
            'onSuccess :' +
              '\n type = ' +
              cbObject.type +
              '\n index = ' +
              cbObject.index
          );
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : getCurrentExternalInput = ' + err.errorMessage
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  async setCurrentExternalInput(
    type: ExternalInput,
    index: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://externalinput/set', {
        parameters: {
          type,
          index,
        },
        onSuccess: (cbObject) => {
          console.log(
            'onSuccess : setCurrentExternalInput' +
              '\n type = ' +
              cbObject.type +
              '\n index = ' +
              cbObject.index
          );
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log(
            'onFailure : setCurrentExternalInput = ' + err.errorMessage
          );
          reject(err);
        },
      });
    });
  }

  async getExternalInputList(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://externalinput/inputlist/get', {
        parameters: {},
        onSuccess: (cbObject: ExternalInputResponse) => {
          console.log('onSuccess : getExternalInputList');
          console.log(`cbObject : ${JSON.stringify(cbObject)}`);
          for (let i = 0; i < cbObject.inputSourceList.length; i++) {
            console.log(
              `inputSourceList[${i}].inputPort : ${cbObject.inputSourceList[i].inputPort}`
            );
            console.log(
              `inputSourceList [${i}].signalDetection : ${cbObject.inputSourceList[i].signalDetection}`
            );
            console.log(
              `type [${i}].type : ${cbObject.inputSourceList[i].type}`
            );
            console.log(
              `index [${i}].index : ${cbObject.inputSourceList[i].index}`
            );
          }
          console.log(`count : ${cbObject.count}`);
          console.log(`currentInputPort : ${cbObject.currentInputPort}`);
          resolve(cbObject);
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure : getExternalInputList : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async resetAV(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        console.info(
          `hcap-service : current input : type: ${this.stbState.currentInputType} : index: ${this.stbState.currentInputIndex}`
        );

        const currentInputType = this.stbState.currentInputType;
        const currentInputIndex = this.stbState.currentInputIndex;
        console.info(`START ${currentInputType} ${currentInputIndex}`);

        if (
          currentInputType === ExternalInput.HDMI ||
          currentInputType === ExternalInput.OTHERS
        ) {
          // was HDMI or UNKNOWN
          const inputs = (await this.getExternalInputList()) as any;
          const hdmiInputs = inputs.list.filter(
            (input) => input.type === ExternalInput.HDMI
          );
          console.info(`hdmi inputs: ${JSON.stringify(hdmiInputs)}`);
          const hdmiIndexes = hdmiInputs.map((hdmi) => hdmi.index);
          console.info(`hdmi indexes: ${JSON.stringify(hdmiIndexes[0])}`);
          const availableIndexes = hdmiIndexes.filter(
            (idx) => idx !== currentInputIndex
          );
          console.info(
            `available indexes: ${JSON.stringify(availableIndexes)}`
          );
          if (availableIndexes.length > 0) {
            console.info(
              `resetAV : switching to HDMI input ${availableIndexes[0]}`
            );
            await this.setCurrentExternalInput(
              ExternalInput.HDMI,
              availableIndexes[0]
            );
          }
          setTimeout(async () => {
            console.info(
              `resetAV : switching back to HDMI input ${currentInputIndex}`
            );
            await this.setCurrentExternalInput(
              currentInputType,
              currentInputIndex
            );
            resolve(this.stbState);
          }, 1000);
        }
        if (
          currentInputType === ExternalInput.TV ||
          currentInputType === ExternalInput.OTHERS
        ) {
          // Was DVB-T or UNKNOWN
          await this.setCurrentExternalInput(ExternalInput.HDMI, 0);
          setTimeout(async () => {
            await this.setCurrentExternalInput(
              currentInputType,
              currentInputIndex
            );
            resolve(this.stbState);
          }, 1000);
        }
      } catch (e) {
        console.error(`hcap-service : resetAv error : ${JSON.stringify(e)}`);
        reject(e);
      }
    });
  }

  // Firmware Upgrade
  startRemoteFirmwareUpgrade(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://system/remotefirmware/upgrade/start', {
        parameters: {
          path: filePath,
        },
        onSuccess: () => {
          console.log(`startRemoteFirmwareUpgrade: onSuccess`);
          resolve();
        },
        onFailure: (err) => {
          console.log(
            `onFailure : errorMessage : setIdcapMode4 = ${err.errorMessage}`
          );
          reject(err.errorMessage);
        },
      });
    });
  }

  getRemoteFirmwareProgress(): Promise<any> {
    return new Promise((resolve, reject) => {
      idcap.request('idcap://system/remotefirmware/progress/get', {
        parameters: {},
        onSuccess: (cbObject) => {
          console.log(`cbObject: ${JSON.stringify(cbObject)}`);
          console.log(`status: ${cbObject.status}`);
          console.log(`upgradeProgress: ${cbObject.upgradeProgress}`);
          resolve(cbObject);
        },
        onFailure: (err) => {
          console.log('onFailure : errorMessage = ' + err.errorMessage);
          reject(err.errorMessage);
        },
      });
    });
  }
}
