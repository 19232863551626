import { SupervisorService } from './../../core/services/supervisor.service';
import { MenuItem } from './../../core/models/menu-item.model';
import { Router } from '@angular/router';
import { LoggerService } from './../../core/services/logger.service';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../core/services/remote-control.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserState } from 'src/app/state/user.reducer';
import { Store } from '@ngrx/store';
import * as UserActions from '../../state/user.actions';
import * as UserSelectors from '../../state/user.selectors';
import * as StbActions from '../../state/stb.actions';
import { Observable, Subscription } from 'rxjs';
import { IdcapService } from '../../core/services/idcap.service';

declare var hcap: any;

@Component({
  selector: 'cm-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss'],
})
export class UtilitiesComponent implements OnInit, OnDestroy {
  public items: MenuItem[];
  public installerItems: MenuItem[];
  private translateStrings: any;
  private INSTALLER_CODE: number[] = [
    ASCII_REMOTE_KEYS.one,
    ASCII_REMOTE_KEYS.one,
    ASCII_REMOTE_KEYS.zero,
    ASCII_REMOTE_KEYS.five,
  ];
  private installerInput: number[] = [];

  showDebug$: Observable<boolean>;
  showDebug: boolean;
  showDebugSubscription: Subscription;

  private showInstallerItems = false;

  constructor(
    private logger: LoggerService,
    private router: Router,
    private supervisor: SupervisorService,
    private auth: AuthService,
    private translate: TranslateService,
    private idcapService: IdcapService,
    private store$: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.idcapService.setIdcapMode1();

    const tokens = [
      'UTILITIES_DEBUG_TITLE',
      'UTILITIES_RELOAD_TITLE',
      'UTILITIES_RESET_TITLE',
      'UTILITIES_REBOOT_TITLE',
      'UTILITIES_STANDBY_TITLE',
      'UTILITIES_ABOUT_TITLE',
      'UTILITIES_DIAGNOSTICS_TITLE',
      'UTILITIES_FIRMWARE_TITLE',
      'UTILITIES_BACK_TITLE',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));

    this.showDebugSubscription = this.store$
      .select(UserSelectors.selectShowDebug)
      .subscribe((showDebug) => {
        this.showDebug = showDebug;
      });

    this.installerItems = [
      {
        id: 1,
        tag: 'debug_bar',
        icon: 'cm:bug',
        label: this.translateStrings.UTILITIES_DEBUG_TITLE,
      },
      {
        id: 2,
        tag: 'firmware',
        icon: 'cm:sun1',
        label: this.translateStrings.UTILITIES_FIRMWARE_TITLE,
      },
      {
        id: 4,
        tag: 'reboot',
        icon: 'cm:power-on',
        label: this.translateStrings.UTILITIES_REBOOT_TITLE,
      },
      // {
      //   id: 5,
      //   tag: 'standby',
      //   icon: 'cm:alert-information',
      //   label: this.translateStrings.UTILITIES_STANDBY_TITLE,
      // },
      {
        id: 8,
        tag: 'diagnostics',
        icon: 'cm:alert-question',
        label: this.translateStrings.UTILITIES_DIAGNOSTICS_TITLE,
      },
    ];

    this.items = [
      {
        id: 2,
        tag: 'reload',
        icon: 'cm:sync',
        label: this.translateStrings.UTILITIES_RELOAD_TITLE,
      },
      {
        id: 3,
        tag: 'reset',
        icon: 'cm:shredder',
        label: this.translateStrings.UTILITIES_RESET_TITLE,
      },
      {
        id: 6,
        tag: 'about',
        icon: 'cm:power-on',
        label: this.translateStrings.UTILITIES_ABOUT_TITLE,
      },

      {
        id: 7,
        tag: 'back',
        icon: 'cm:arrow-left',
        label: this.translateStrings.UTILITIES_BACK_TITLE,
      },
    ];
  }

  ngOnDestroy(): void {
    this.idcapService.setIdcapMode2();
    this.showDebugSubscription.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if (
      $event.keyCode === ASCII_REMOTE_KEYS.one ||
      $event.keyCode === ASCII_REMOTE_KEYS.zero ||
      $event.keyCode === ASCII_REMOTE_KEYS.five
    ) {
      console.info(`key pressed: ${$event.keyCode}`);
      this.installerInput.push($event.keyCode);
      if (this.installerInput.length === 4) {
        console.info(`installer: ${JSON.stringify(this.INSTALLER_CODE)}`);
        if (
          JSON.stringify(this.installerInput) ===
          JSON.stringify(this.INSTALLER_CODE)
        ) {
          console.info('installer code entered!');
          this.items = [...this.installerItems, ...this.items];
        } else {
          this.installerInput = [];
        }
      }
    } else {
      this.installerInput = [];
    }

    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToMenu();
    }
    return false;
  }

  onItemSelected(item): void {
    switch (item.tag) {
      case 'debug_bar':
        this.toggleDebug();
        break;
      case 'reload':
        this.reload();
        break;
      case 'reset':
        this.reset();
        break;
      case 'reboot':
        this.reboot();
        break;
      case 'standby':
        this.standby();
        break;
      case 'about':
        this.goToAbout();
        break;
      case 'diagnostics':
        this.goToDiagnostics();
        break;
      case 'firmware':
        this.goToFirmware();
        break;
      case 'back':
        this.goToMenu();
        break;
    }
  }

  goToMenu(): void {
    this.router.navigate(['dashboard/menu']);
  }

  goToDiagnostics(): void {
    this.router.navigate(['dashboard/diagnostics']);
  }

  goToFirmware(): void {
    this.router.navigate(['dashboard/firmware']);
  }

  goToAbout(): void {
    this.router.navigate(['dashboard/about']);
  }

  toggleDebug(): void {
    // toggle debug toolbar
    this.store$.dispatch(UserActions.toggleShowDebug());
  }

  reload(): void {
    this.store$.dispatch(StbActions.reload());
  }

  reset(): void {
    this.store$.dispatch(UserActions.logout());
  }

  reboot(): void {
    this.store$.dispatch(StbActions.reboot());
  }

  standby(): void {
    this.store$.dispatch(StbActions.standby());
  }
}
