import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  getMatIconFailedToSanitizeLiteralError,
  MatIconRegistry,
} from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoggerService } from './core/services/logger.service';
import { SupervisorService } from './core/services/supervisor.service';
import { catchError, map, take } from 'rxjs/operators';
import { ASCII_REMOTE_KEYS } from './core/services/remote-control.service';
import adapter from 'webrtc-adapter';
import { TranslateService } from '@ngx-translate/core';
import { RouterExtensionService } from './core/services/router-extension.service';
import { PreviousRouteService } from './core/services/previous-route.service';
import { Store } from '@ngrx/store';
import * as UserActions from 'src/app/state/user.actions';
import * as StbActions from 'src/app/state/stb.actions';
import * as UserSelectors from 'src/app/state/user.selectors';
import * as PresenceSelectors from 'src/app/state/presence.selectors';
import * as ConferencingSelectors from 'src/app/state/conferencing.selectors';
import { Resident } from './core/models/resident.model';
import { SupporterResidentAssociation } from './core/models/supporter-resident-association.model';
import { Observable, of, Subscription } from 'rxjs';
import * as ConferencingActions from './state/conferencing.actions';
import { Customer } from './core/models/customer.model';
import { HcapService } from './core/services/hcap.service';
import { IdcapService } from './core/services/idcap.service';

declare var idcap: any;
declare var hcap: any;

@Component({
  selector: 'tv-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cm-tv-lg';

  showDebug$: Observable<boolean>;
  currentUser$: Observable<Resident>;
  currentUser: Resident;
  currentUserSubscription: Subscription;

  currentCustomer$: Observable<Customer>;
  currentCustomer: Customer;
  currentCustomerSubscription: Subscription;

  acceptCall$: Observable<boolean>;
  acceptCall: boolean;
  acceptCallSubscription: Subscription;

  associations$: Observable<SupporterResidentAssociation[]>;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private translate: TranslateService,
    private routerExtension: RouterExtensionService,
    private supervisor: SupervisorService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private hcapService: HcapService,
    private idcapService: IdcapService,
    private previousRouteService: PreviousRouteService,
    private store$: Store
  ) {
    this.iconRegistry.addSvgIconSetInNamespace(
      'cm',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cm-icons.svg')
    );
  }

  ngOnInit(): void {
    const isIdcap = navigator.userAgent.includes('Web0S');
    this.store$.dispatch(UserActions.setIsIdcap({ isIdcap }));

    console.info(`webrtc-adpater: browser : ${adapter.browserDetails.browser}`);
    console.info(`webrtc-adpater: version : ${adapter.browserDetails.version}`);

    // Set Language
    this.translate.setDefaultLang('en-GB');

    if (isIdcap) {
      idcap.isLogEnabled = true;
      // Set IDCAP browser debug mode
      this.idcapService.setBrowserDebugModeOn().then((response) => {
        console.log(`setBrowserDebugModeOn: ${response}`);
      });

      // Set Instant Power On
      this.idcapService.getInstantPower().then((response) => {
        console.log(`getInstantPowerOn: ${response}`);
        if (response.value !== '2') {
          console.info(`setting instant power on`);
          this.idcapService.setInstantPowerOn().then((powerOn) => {
            console.log(`setInstantPowerOn successful... rebooting`);
            this.idcapService.rebootTv();
          });
        }
      });

      // Set remote control mode
      this.idcapService.setIdcapMode1();

      // Get locale from TV
      this.idcapService
        .getLocale()
        .then((current: any) => {
          console.info(
            `setting translation locale to ${current.specifier} (${current.country})`
          );
          this.translate.use(current.specifier);
        })
        .catch((f) => console.error(`failed to get locale: ${f.errorMessage}`));

      // Get Power Mode
      this.store$.dispatch(StbActions.getPowerMode());

      // Get Current Input
      // TODO: handle get external input for idcap
      // this.hcapService.getCurrentExternalInput().then((current: any) => {
      //   console.info(
      //     `current input: type: ${current.type} index: ${current.index}`
      //   );
      //   this.store$.dispatch(
      //     StbActions.setCurrentInput({
      //       typ: current.type,
      //       index: current.index,
      //     })
      //   );
      // });
    }

    this.store$
      .select(UserSelectors.selectCurrentUser)
      .pipe(take(1))
      .subscribe((currentUser) => (this.currentUser = currentUser));

    this.showDebug$ = this.store$.select(UserSelectors.selectShowDebug);
    this.currentUser$ = this.store$.select(UserSelectors.selectCurrentUser);
    this.acceptCall$ = this.store$.select(
      ConferencingSelectors.selectAcceptCall
    );

    this.currentCustomer$ = this.store$.select(
      UserSelectors.getCurrentCustomer
    );
    this.associations$ = this.store$.select(
      PresenceSelectors.selectSupporterResidentAssociations
    );

    this.currentUserSubscription = this.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });

    this.currentCustomerSubscription = this.currentCustomer$.subscribe(
      (customer: Customer) => {
        this.currentCustomer = customer;
      }
    );

    this.acceptCallSubscription = this.acceptCall$.subscribe((acceptCall) => {
      this.acceptCall = acceptCall;
    });

    this.store$.dispatch(ConferencingActions.testVideoInputDevices({}));
  }

  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
    this.currentCustomerSubscription.unsubscribe();
  }

  @HostListener('document:on_destroy')
  handleOnDestroy(): void {
    console.info(`on_destroy called`);

    idcap.request('idcap://application/destroysignal/begin', {
      parameters: {},
      onSuccess: async () => {
        console.log(`beginDestroy onSuccess`);

        // clean up resources
        console.log(`setting key mode 0`);
        await this.idcapService.setIdcapMode0();

        idcap.request('idcap://application/destroysignal/end', {
          parameters: {},
          onSuccess: () => {
            console.log(`endDestroy onSuccess`);
          },
          onFailure: (err) => {
            console.log(
              `endDestroy onFailure : errorMessage = " + err.errorMessage`
            );
          },
        });
      },
      onFailure: (err) => {
        console.log(
          `beginDestroy onFailure : errorMessage = " + err.errorMessage`
        );
      },
    });
  }

  @HostListener('document:external_input_changed')
  handleExternalInputChanged(): void {
    console.info(`hcap event: external_input_changed`);
    // TODO: handle get external input changed for idcap
    // this.hcapService.getCurrentExternalInput().then((current: any) => {
    //   console.info(
    //     `current input: type: ${current.type} index: ${current.index}`
    //   );
    //   this.store$.dispatch(
    //     StbActions.setCurrentInput({ typ: current.type, index: current.index })
    //   );
    // });
  }

  @HostListener('document:idcap::power_mode_changed')
  handlePowerModeChange(event: Event): void {
    console.info(`idcap::power_mode_changed: ${JSON.stringify(event)}`);

    console.log(`dispatching getPowerMode`);

    this.idcapService
      .getPowerMode()
      .then((result) => {
        console.log(`getPowerMode: ${result}`);
        this.store$.dispatch(
          StbActions.getPowerModeSuccess({ powerMode: result })
        );
      })
      .catch((error) => {
        console.error(`error: ${JSON.stringify(error)}`);
        this.store$.dispatch(StbActions.getPowerModeFailure({ error }));
      });
  }

  @HostListener('document:hcap_application_focus_changed')
  handleHcapApplicationFocusChange(event: Event): void {
    console.info(`hcap_application_focus_changed: ${JSON.stringify(event)}`);
  }

  @HostListener('document:firmware_upgrade_status_changed')
  handleFirmwareUpgradeStatusChanged(event: any): void {
    console.info(
      `idcap::firmware_upgrade_status_changed: ${JSON.stringify(event)}`
    );
    console.info(`type: ${event.type} status: ${event.status}`);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    this.logger.info(`global keydown: ${event.keyCode} `);

    switch (event.keyCode) {
      case ASCII_REMOTE_KEYS.back:
        break;
      case ASCII_REMOTE_KEYS.ok:
        break;
      case ASCII_REMOTE_KEYS.left:
        break;
      case ASCII_REMOTE_KEYS.up:
        break;
      case ASCII_REMOTE_KEYS.right:
        break;
      case ASCII_REMOTE_KEYS.down:
        break;
      case ASCII_REMOTE_KEYS.zero:
        break;
      case ASCII_REMOTE_KEYS.one:
        break;
      case ASCII_REMOTE_KEYS.two:
        break;
      case ASCII_REMOTE_KEYS.three:
        break;
      case ASCII_REMOTE_KEYS.four:
        break;
      case ASCII_REMOTE_KEYS.five:
        break;
      case ASCII_REMOTE_KEYS.six:
        break;
      case ASCII_REMOTE_KEYS.seven:
        break;
      case ASCII_REMOTE_KEYS.eight:
        break;
      case ASCII_REMOTE_KEYS.nine:
        break;
      case ASCII_REMOTE_KEYS.epg:
        break;
      case ASCII_REMOTE_KEYS.info:
        if (
          this.currentCustomer !== undefined &&
          this.currentCustomer.settings_enabled === true &&
          this.acceptCall === false
        ) {
          this.logger.info('Show settings dialog');
          if (this.supervisor.showSettings) {
            this.supervisor.showSettings = false;
            this.supervisor.showAVSource = false;
            this.supervisor.showPower = false;
            this.supervisor.showAssistance = false;
            this.store$.dispatch(UserActions.navigateToHome());
          } else {
            this.supervisor.showSettings = true;
            this.supervisor.showAVSource = false;
            this.supervisor.showPower = false;
            this.supervisor.showAssistance = false;

            // GO TO SETTINGS
            switch (this.currentUser.customer.name) {
              case 'Doro':
                this.router.navigate(['doro/dashboard/home']);
                break;
              default:
                this.router.navigate(['dashboard/menu']);
                break;
            }
          }
        }
        break;
      case ASCII_REMOTE_KEYS.text:
        this.logger.info('Subtitles button pressed');
        this.logger.warn('Subtitles not currently supported with HCAP');
        break;
      case ASCII_REMOTE_KEYS.subtitles:
        this.logger.info('Subtitles button pressed');
        break;
      case ASCII_REMOTE_KEYS.red:
        this.logger.info('Red Button pressed');
        break;
      case ASCII_REMOTE_KEYS.green:
        this.logger.info('Green Button pressed');
        this.logger.info(`Show Assistance dialog`);
        if (
          this.currentUser !== undefined &&
          this.currentCustomer !== undefined &&
          this.currentCustomer.assistance_enabled === true &&
          this.acceptCall === false
        ) {
          if (this.currentUser.is_assistance_active) {
            if (this.supervisor.showAssistance) {
              this.supervisor.showAssistance = false;
              this.supervisor.showPower = false;
              this.supervisor.showSettings = false;
              this.supervisor.showAVSource = false;
              this.store$.dispatch(UserActions.navigateToHome());
            } else {
              this.supervisor.showAssistance = true;
              this.supervisor.showSettings = false;
              this.supervisor.showAVSource = false;
              this.supervisor.showPower = false;
              this.router.navigate(['assistance/request-assistance']);
            }
          } else {
            this.logger.warn(
              'Assistance requests disabled for ' +
                this.currentUser.username +
                ', action cancelled'
            );
          }
        } else {
          this.logger.warn('No current user, action cancelled');
        }
        break;
      case ASCII_REMOTE_KEYS.yellow:
        this.logger.info('Yellow Button pressed');
        break;
      case ASCII_REMOTE_KEYS.blue:
        this.logger.info('Blue Button pressed');
        break;
      case ASCII_REMOTE_KEYS.av:
        this.logger.info('AV Button pressed');
        if (
          this.currentUser.set_top_box.av_key_enabled &&
          this.acceptCall === false
        ) {
          this.logger.info('Show AV Source dialog');
          if (this.supervisor.showAVSource) {
            this.supervisor.showAVSource = false;
            this.supervisor.showSettings = false;
            this.supervisor.showPower = false;
            this.supervisor.showAssistance = false;
            this.store$.dispatch(UserActions.navigateToHome());
          } else {
            this.supervisor.showAVSource = true;
            this.supervisor.showSettings = false;
            this.supervisor.showPower = false;
            this.supervisor.showAssistance = false;

            this.router.navigate(['av-source/menu']);
          }
        } else {
          this.logger.info('AV Button disabled, action cancelled');
        }
        break;
      case ASCII_REMOTE_KEYS.menu:
        this.logger.info('Menu Button pressed');
        break;
      case ASCII_REMOTE_KEYS.power:
        this.logger.info('Power Button pressed');
        if (this.acceptCall === false) {
          this.logger.info('Show Power dialog');
          if (this.supervisor.showPower) {
            this.supervisor.showPower = false;
            this.supervisor.showSettings = false;
            this.supervisor.showAVSource = false;
            this.supervisor.showAssistance = false;
            this.store$.dispatch(UserActions.navigateToHome());
          } else {
            this.supervisor.showPower = true;
            this.supervisor.showSettings = false;
            this.supervisor.showAVSource = false;
            this.supervisor.showAssistance = false;
            this.router.navigate(['power/menu']);
          }
        } else {
          this.logger.warn('Power button disabled, call in progress');
        }
        break;
      case ASCII_REMOTE_KEYS.channel_up:
        this.logger.info('Channel Up Button pressed');
        break;
      case ASCII_REMOTE_KEYS.channel_down:
        this.logger.info('Channel Down Button pressed');
        break;
      default:
        this.logger.warn(
          `key code ${event.keyCode} received but no handler present`
        );
        break;
    }
  }
}
