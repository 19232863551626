<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'UTILITIES_FIRMWARE_TITLE' | translate }}"
>
  <div>
    <ng-container>
      <div>
        <div class="status-container">
          <h1>Status: {{ status }}</h1>
          <h1>Progress: {{ progress }}</h1>
        </div>
        <cm-menu-grid
          [items]="firmwareItems"
          (itemSelected)="onItemSelected($event)"
          style="margin: 50px"
          [columns]="4"
        ></cm-menu-grid>
      </div>
    </ng-container>
  </div>
</cm-overlay>
