import { Component, HostListener, OnInit } from '@angular/core';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../../core/models/menu-item.model';
import { IdcapService } from '../../core/services/idcap.service';

@Component({
  selector: 'cm-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss'],
})
export class FirmwareComponent implements OnInit {
  translateStrings: any;
  firmwareItems: MenuItem[];

  public status: string;
  public progress: number;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private idcapService: IdcapService
  ) {}

  ngOnInit(): void {
    const tokens = ['FIRMWARE_TITLE', 'FIRMWARE_UPDATE_NOW', 'FIRMWARE_EXIT'];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));

    this.firmwareItems = [
      {
        id: 1,
        tag: 'update_now',
        icon: 'cm:sun1',
        label: this.translateStrings.FIRMWARE_UPDATE_NOW,
      },
      {
        id: 2,
        tag: 'exit',
        icon: 'cm:cross-circle',
        label: this.translateStrings.FIRMWARE_EXIT,
      },
    ];
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToUtilities();
    }
    return false;
  }

  onItemSelected(item: MenuItem): void {
    switch (item.tag) {
      case 'update_now':
        this.updateFirmware();
        break;
      case 'exit':
        this.goToUtilities();
        break;
    }
  }

  goToUtilities(): void {
    this.router.navigate(['dashboard/utilities']);
  }

  updateFirmware(): void {
    const firmwareUrl =
      'https://infotube-api-staging.s3.amazonaws.com/sunrise_images/starfish-dvb-secured-k6hp-17.255.japoon4hotel.k6hp-101-04.70.00_prodkey_n_gRsgyvO.epk';

    this.idcapService
      .startRemoteFirmwareUpgrade(firmwareUrl)
      .then((result) => {
        console.info(`Firmware update complete: ${result}`);
      })
      .catch((error) => {
        console.error(`Firmware update error: ${error}`);
      });

    const interval = setInterval(() => {
      this.idcapService
        .getRemoteFirmwareProgress()
        .then((response) => {
          console.info(`Firmware upgrade status: ${response.status}`);
          this.status = response.status;
          this.progress = response.updateProgress;
          if (response.status === 'finish' || response.status === 'failed') {
            clearInterval(interval);
          }
        })
        .catch((error) => {
          console.error(`Firmware upgrade status error: ${error}`);
          clearInterval(interval);
        });
    }, 1000);
  }
}
